import axios from "axios";
import { TOKEN } from "./localStorage";
import { Notification } from "./notification";
export const url = "http://restapi-ssm-demo.autotron.vn";
export const instanceAxios = axios.create({
  baseURL: url,
});
instanceAxios.interceptors.request.use(async (config: any) => {
  const accessToken = localStorage.getItem(TOKEN) || "";
  config.headers = {
    Authorization: `Bearer `,
    Accept: "application/json , application/msn.api.v1+json",
    ResponseType: "blob",
  };
  return config;
});
instanceAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error.config;
    if (error?.response?.status === 401) {
      Notification("error", "Phiên đăng nhập hết hạn vui lòng đăng nhập lại");
      localStorage.clear();
      window.location.href = "/";

      // TODO retrieve jwt token by refresh token
      // const result = await refreshToken(localStorage.getItem(REFRESHTOKEN))
      // if (result) {
      //     config.headers = {
      //         authorization: `Bearer ${result.data?.data?.accessToken}`,
      //     }
      //     localStorage.setItem(
      //         REFRESHTOKEN,
      //         result?.data?.data?.refreshToken
      //     )
      //     localStorage.setItem(
      //         TOKEN,
      //         result?.data?.data?.accessToken
      //     )

      //     return instanceAxios.request(error.config)

      // } else {
      //     localStorage.clear()
      //     window.location.href = "/"
      // }
    }

    if (error?.response?.status >= 500) {
      // localStorage.clear()
      return error;
    }
    throw error;
  }
);
